import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import { EventEmitter } from 'events'
import { getMenus } from './config'
export const menuEmitter = new EventEmitter()
const { SubMenu } = Menu

class PortalMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: false,
      defaultSelectedKeys: ['0'],
      menuData: [],
    }
  }

  componentDidMount() {
    menuEmitter.addListener('setSeleted', (data) => {
      console.warn(data)
      this.setState({
        defaultSelectedKeys: [],
      })
    })
    this.requestMenu()
  }

  async requestMenu() {
    const routes = getMenus()
    const me = this.props.portal.myData
    let menus = me.menus
    let data = menus.map((item, index) => {
      item['key'] = index
      item.title = item.name
      for (let i = 0; i < routes.length; i++) {
        let route = routes[i]
        if (item.dataUrl === route.key) {
          item.icon = route.icon
          break
        }
      }
      return item
    })
    data.push({})

    this.setState({
      menuData: data,
    })
  }

  render() {
    const setMenu = (item) => {
      if (item.childList && item.childList.length > 0) {
        return (
          <SubMenu key={item.key} icon={<MenuUnfoldOutlined style={{ margin: 0, width: 20 }} />} title={item.name}>
            {item.childList.map((item, index) => {
              return setMenu(item)
            })}
          </SubMenu>
        )
      } else if (item.key) {
        console.log('key---', item)
        return (
          <Menu.Item key={item.key} icon={<FontAwesomeIcon icon={item.icon} style={{ margin: 0, width: 20 }} />}>
            <Link to={item.dataUrl}>{item.name}</Link>
          </Menu.Item>
        )
      }
    }

    return (
      <Menu
        id='portal-menu'
        style={{ maxWidth: 200 }}
        selectedKeys={this.state.defaultSelectedKeys}
        theme='dark'
        mode='inline'
        onClick={(menuItemInfo) => {
          console.log('menuItemInfo', menuItemInfo.key)
          if (menuItemInfo.key === 'other') return
          this.setState({
            defaultSelectedKeys: [menuItemInfo.key],
          })
        }}
      >
        {this.state.menuData.map((item, index) => {
          return setMenu(item)
        })}
      </Menu>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    portal: state.portal,
  }
}

const mapDispachToProps = (dispatch) => {
  return {
    setPortalReducer: (target) => {
      dispatch({
        type: 'setPortalReducer',
        payload: target,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispachToProps)(withRouter(PortalMenu))
